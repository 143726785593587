export default [
  /* {
    key: '1', name: '企业管理', icon: 'table', submenus: [
      { key: '/businessData/client', name: '企业信息'},
      { key: '/businessData/supplier', name: '从业资质'},
      { key: '/businessData/warehouse', name: '从业人员管理'},
      { key: '/businessData/settlement_account', name: '设备管理'},
      { key: '/businessData/revenue_expenditure_items', name: '企业制度'},
    ]
  }, */
  {
    key: '1', name: '企业自查', icon: 'check-square', submenus: [
      { key: '/control/daily_control', name: '日管控' },
      { key: '/control/weekly_control', name: '周排查' },
      { key: '/control/monthly_control', name: '月调度' }
    ]
  },
  {
    key: '2', name: '基础数据', icon: 'table', submenus: [
      { key: '/basicData/client', name: '客户管理'},
      { key: '/basicData/supplier', name: '供应商管理'},
      { key: '/basicData/warehouse', name: '仓库管理'},
      { key: '/basicData/settlement_account', name: '结算账户'},
      { key: '/basicData/revenue_expenditure_items', name: '收支项目'}
    ]
  },
  {
    key: '3', name: '产品及原料管理', icon: 'appstore', submenus: [
      { key: '/goods/information', name: '产品及原料信息' },
      { key: '/goods/classification', name: '产品及原料分类' },
      { key: '/goods/unit', name: '产品及原料单位' }
      /* { key: '/goods/temporary_warning', name: '临期预警' },
      { key: '/goods/rawMaterial', name: '原辅料管理' },
      { key: '/goods/warehouse', name: '原辅料仓库' },
      { key: '/goods/rawMaterialInfo', name: '原辅料信息' } */
    ]
  },
  {
    key: '4', name: '追溯管理', icon: 'qrcode', submenus: [
      { key: '/from/from_product', name: '追溯产品管理' },
      { key: '/produce/formulas', name: '配方及投料记录' },
      { key: '/from/from_result', name: '半成品及成品管理' },
      { key: '/produce/storage', name: '成品入库创建' },
      { key: '/produce/purchase_record', name: '成品入库列表' }
    ]
  },
  {
    key: '5', name: '采购管理', icon: 'shopping-cart', submenus: [
      { key: '/purchasing/purchase_create', name: '采购开单' },
      { key: '/purchasing/purchase_record', name: '采购入库' },
      { key: '/purchasing/purchase_return_create', name: '采购退货' },
      { key: '/purchasing/return_record', name: '退货记录' }
      /* { key: '/purchasing/qualifiedInspection', name: '原辅料检验合格合格报告' },
      { key: '/purchasing/supplier', name: '供货商管理' },
      { key: '/purchasing/buyers', name: '购货商管理' }, */
    ]
  },
  {
    key: '6', name: '生产过程管理', icon: 'dollar', submenus: [
      { key: '/produce/outbound', name: '原辅料使用记录' },
      /* { key: '/produce/food_additives', name: '食品添加剂使用记录' }, */
      { key: '/produce/process_control', name: '生产加工过程控制' },
      { key: '/produce/technology', name: '生产工艺参数' },
      { key: '/produce/device_manage', name: '设备管理' }
    ]
  },
  {
    key: '7', name: '检验信息管理', icon: 'copy', submenus: [
      { key: '/inspect/record', name: '留存样品留样记录' },
      { key: '/inspect/report', name: '检验报告' }
    ]
  },
  {
    key: '8', name: '库存管理', icon: 'container', submenus: [
      { key: '/warehouse/inStock', name: '入库任务' },
      { key: '/warehouse/outStock', name: '出库任务' },
      { key: '/warehouse/inventory', name: '盘点' },
      { key: '/warehouse/allocation', name: '调拨' },
      { key: '/warehouse/flow', name: '库存流水' }
    ]
  },
  {
    key: '9', name: '销售管理', icon: 'credit-card', submenus: [
      { key: '/sale/sale_create', name: '销售开单' },
      { key: '/sale/sale_record', name: '销售出库' },
      { key: '/sale/sale_return_create', name: '销售退货' },
      { key: '/sale/sale_return_record', name: '退货记录' }
    ]
  },
  {
    key: '10', name: '财务管理', icon: 'dollar', submenus: [
      { key: '/finance/arrears_payable', name: '应付欠款' },
      { key: '/finance/payment', name: '付款' },
      { key: '/finance/arrears_receivable', name: '应收欠款' },
      { key: '/finance/collection', name: '收款' },
      { key: '/finance/account_transfer', name: '账户转账' },
      { key: '/finance/income_and_pay', name: '日常收支' },
      { key: '/finance/flow', name: '资金流水' }
    ]
  },
  {
    key: '11', name: '冷藏品管理', icon: 'database', submenus: [
      { key: '/refrigerate/keep', name: '贮存管理' },
      { key: '/refrigerate/monitors', name: '定期监测记录' },
      { key: '/refrigerate/transports', name: '运输过程记录' }
    ]
  },
  {
    key: '12', name: '不合格品管理', icon: 'build', submenus: [
      { key: '/dispatch/call_back', name: '召回管理' },
      { key: '/dispatch/call_handle', name: '召回产品处理' },
      { key: '/dispatch/unqualified', name: '不合格品处理' }
    ]
  },
  {
    key: '13', name: '物联网技术管理', icon: 'shake', submenus: [
      { key: '/IOT/intelligence', name: '智能化管理' }
    ]
  },
  {
    key: '14', name: '企业管理', icon: 'team', submenus: [
      { key: '/enterprise/enterpriseInfo', name: '企业信息' },
      { key: '/enterprise/employeeView', name: '从业人员' },
      { key: '/enterprise/system', name: '制度管理' }
    ]
  },
  /* {
    key: '15', name: '追溯体系管理', icon: 'apartment', submenus: [
      { key: '/IOT/establish_mechanism', name: '建立过程机制' },
      { key: '/IOT/inspection_cycle', name: '检查及审核周期' },
      { key: '/IOT/corrective_action', name: '纠正措施管理' }
    ]
  }, */
  {
    key: '15', name: '报表统计', icon: 'line-chart', submenus: [
      { key: '/report/sale_report', name: '销售报表' },
      { key: '/report/purchase_report', name: '采购报表' },
      { key: '/report/stock_report', name: '库存报表' },
      { key: '/report/income_expense_statistics', name: '收支统计' },
      { key: '/report/batch_report', name: '批次报表' }
    ]
  },
  {
    key: '16', name: '系统管理', icon: 'setting', submenus: [
      { key: '/role', name: '角色管理' },
      { key: '/account', name: '账号管理' },
      { key: '/config', name: '系统配置' }
    ]
  }
]
